import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { usePost } from "../../network/queries";
import {
  BlogCategIcon,
  BlogPostAuthor,
  BlogPostAuthorIcon,
  BlogPostCategoriesContainer,
  BlogPostCategory,
  BlogPostContainer,
  BlogPostContent,
  BlogPostExcerptContainer,
  BlogPostImageContainer,
  BlogPostShareContent,
  BlogPostSocialNetwork,
  BlogPostSocialNetworkItem,
  BlogPostTag,
  BlogPostTags,
  BlogPostTitle,
  PostDate,
} from "./styles";

import { useParams } from "react-router-dom";
import { Post } from "../../models/blog.models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";
import { transformPostDate } from "../../utils";
import {
  faFacebook,
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useCurrentUrl } from "../../hooks";
import { NotFound } from "../../components/Error/NotFound";

export const BlogPost = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const currentUrl = useCurrentUrl();
  const [post, setPost] = useState<Post | undefined>(undefined);
  const { status, data, error, isFetching, refetch } = usePost(slug);

  useEffect(() => {
    if (data && data.length > 0) {
      setPost(data[0]);
    }
  }, [data]);

  return (
    <BlogPostContainer>
      {!post && !isFetching && <NotFound></NotFound>}

      {post && (
        <>
          <BlogPostCategoriesContainer>
            <BlogPostCategory>{t("blog.section")}</BlogPostCategory>
            <BlogCategIcon>
              <FontAwesomeIcon icon={faChevronRight} />
            </BlogCategIcon>
            {post.category_names.map((categ) => (
              <BlogPostCategory>{`${categ}`}</BlogPostCategory>
            ))}
          </BlogPostCategoriesContainer>

          <BlogPostTitle
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          ></BlogPostTitle>

          <BlogPostTags>
            {post.tag_names.map((tag) => (
              <BlogPostTag>{`#${tag}`}</BlogPostTag>
            ))}
          </BlogPostTags>

          <PostDate>{transformPostDate(post.date)}</PostDate>

          <BlogPostExcerptContainer
            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
          ></BlogPostExcerptContainer>

          <BlogPostImageContainer>
            <img src={post.featured_image_src} />
          </BlogPostImageContainer>
          <BlogPostContent
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          ></BlogPostContent>
          <BlogPostShareContent>
            <BlogPostAuthor>
              <BlogPostAuthorIcon>
                <FontAwesomeIcon icon={faUserAstronaut} />
              </BlogPostAuthorIcon>

              {post.author_name}
            </BlogPostAuthor>
            <BlogPostSocialNetwork>
              {/* <BlogPostSocialNetworkItem
                href={`https://twitter.com/intent/tweet?text=${post.title.rendered}&url=${currentUrl}}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faXTwitter} />
              </BlogPostSocialNetworkItem> */}
              <BlogPostSocialNetworkItem
                href={`https://www.facebook.com/sharer.php?u=${currentUrl}`}
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </BlogPostSocialNetworkItem>
              <BlogPostSocialNetworkItem
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${post.title.rendered}`}
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </BlogPostSocialNetworkItem>
            </BlogPostSocialNetwork>
          </BlogPostShareContent>
        </>
      )}
    </BlogPostContainer>
  );
};
