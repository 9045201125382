import styled from "styled-components";

export const SmallLogoImage = styled.img`
  height: 40px;
`;

export const BigLogoImage = styled.img`
  height: 250px;
`;

export const LargeLogoImage = styled.img`
  height: 500px;
`;

export const MediumLogoImage = styled.img`
  height: 250px;
`;

export const NormalLogoImage = styled.img`
  height: 120px;
`;
