import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import React, { FC, ReactElement } from "react";
import { Loader } from "../Loader";
import { ButtonContainer } from "./styles";

export interface ButtonProps extends MuiButtonProps {
  text: string;
  isLoading?: boolean;
}
export const Button: FC<ButtonProps> = (props): ReactElement => {
  const { text, onClick, isLoading, ...rest } = props;
  return (
    <ButtonContainer>
      {isLoading ? (
        <Loader size="small" />
      ) : (
        <MuiButton variant="outlined" onClick={onClick} {...rest}>
          {text}
        </MuiButton>
      )}
    </ButtonContainer>
  );
};
