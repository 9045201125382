import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../components/Card";
import { Post } from "../../models/blog.models";
import { usePosts } from "../../network/queries";
import {
  BlogContainer,
  PostCategoriesContainer,
  PostCategory,
  PostContent,
  PostDate,
  PostFooter,
  PostHeader,
  PostItem,
  PostLoarderContainer,
  PostsContainer,
  PostTag,
  PostTags,
  PostText,
  PostTitleContainer,
  SearchContainer,
  WaveContainer,
} from "./styles";
import { Title } from "../../components/Title";
import { Link } from "../../components/Link";
import { Box, FormControl, Stack } from "@mui/material";
import { TextField } from "../../components/TextField";
import { Button } from "../../components/Button";
import { Wave } from "../../components/Wave";
import { useIsMobileView } from "../../hooks";
import { transformPostDate } from "../../utils";
import { FadeInSection } from "../../components/MotionSection";
import { Loader } from "../../components/Loader";

export const Blog = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const { status, data, error, isFetching, refetch } = usePosts(searchValue);

  const isMobileView = useIsMobileView();

  const handleSearch = () => {
    refetch();
  };

  const handleEnterPress = (event: any) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    handleSearch();
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, []);

  return (
    <BlogContainer>
      <WaveContainer>
        <Wave />
      </WaveContainer>
      <FadeInSection triggerAtStart direction={"right"}>
        <Title
          title={t("blog.title")}
          variant="secondary"
          subtitle={t("blog.subtitle")}
        />
        <SearchContainer>
          <Card
            width={800}
            content={
              <Stack spacing={2} direction={isMobileView ? "column" : "row"}>
                <FormControl sx={{ mb: 2, width: "100%" }}>
                  <TextField
                    id="search"
                    variant="filled"
                    type={"text"}
                    onChange={(event: any) =>
                      setSearchValue(event.target.value)
                    }
                    placeholder={t("blog.searchInput")}
                  />
                </FormControl>
                <Box sx={{ textAlign: isMobileView ? "center" : "left" }}>
                  <Button
                    type="submit"
                    text={t("Rechercher")}
                    onClick={handleSearch}
                  />
                </Box>
              </Stack>
            }
          />
        </SearchContainer>
      </FadeInSection>
      {isFetching && (
        <PostLoarderContainer>
          <Loader variant="secondary" />
        </PostLoarderContainer>
      )}
      {!isFetching && (
        <FadeInSection triggerAtStart direction={"top"}>
          <PostsContainer>
            {data &&
              data.length > 0 &&
              data.map((x: Post) => (
                <PostItem>
                  <Card
                    header={
                      <PostHeader>
                        {x.category_names && x.category_names.length > 0 && (
                          <PostCategoriesContainer>
                            {x.category_names.map((categ) => (
                              <PostCategory>{categ}</PostCategory>
                            ))}
                          </PostCategoriesContainer>
                        )}
                        <PostDate>{transformPostDate(x.date)}</PostDate>
                      </PostHeader>
                    }
                    width={isMobileView ? undefined : 350}
                    title={
                      <PostTitleContainer
                        dangerouslySetInnerHTML={{ __html: x.title.rendered }}
                      ></PostTitleContainer>
                    }
                    content={
                      <PostContent>
                        <PostText
                          dangerouslySetInnerHTML={{
                            __html: x.excerpt.rendered,
                          }}
                        ></PostText>
                        {x.tag_names && x.tag_names.length > 0 && (
                          <PostTags>
                            {x.tag_names.map((tag) => (
                              <PostTag>{`#${tag}`}</PostTag>
                            ))}
                          </PostTags>
                        )}
                      </PostContent>
                    }
                    footer={
                      <PostFooter>
                        <Link
                          text={t("services.button")}
                          to={`/blog/${x.slug}`}
                          variant="primary"
                        />
                      </PostFooter>
                    }
                  />
                </PostItem>
              ))}
          </PostsContainer>

          {data && data.length === 0 && (
            <SearchContainer>
              <Card
                width={800}
                content={
                  <>
                    <p>{t("blog.postNotFound1")}</p>
                    <p>{t("blog.postNotFound2")}</p>
                  </>
                }
              />
            </SearchContainer>
          )}
        </FadeInSection>
      )}
    </BlogContainer>
  );
};
