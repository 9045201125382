import styled from "styled-components";

export const LinkContainer = styled.div`
  a {
    text-decoration: none;
    color: ${(props) => props.theme.text.tertiary};
    font-size: ${(props) => props.theme.fontSize.text};
    padding: 10px 35px;
    text-transform: unset;
    font-family: ${(props) => props.theme.font.primary};
    border: none;
    &:hover {
      cursor: pointer;
      font-weight: bold;
      border: none;
    }

    &.secondary {
      text-decoration: none;
      background: ${(props) => props.theme.bg.tertiary};
      color: ${(props) => props.theme.text.secondary};
      font-size: ${(props) => props.theme.fontSize.text};
      padding: 10px 35px;
      border-radius: 25px;
      text-transform: unset;
      font-family: ${(props) => props.theme.font.primary};
      box-shadow: ${(props) => props.theme.shadow.primary};
      border: none;
      font-weight: normal;
      &:hover {
        border: none;
        color: ${(props) => props.theme.text.secondary};
        background: ${(props) => props.theme.bg.quaternary};
      }
    }
  }
`;
