import styled from "styled-components";
import { breakpoints, devices } from "../../constants";

export const PolicyTitle = styled.h1`
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.mobileTitle};
  @media only screen and ${devices.lg} {
    font-size: ${(props) => props.theme.fontSize.title};
  }
`;

export const PolicyContainer = styled.div`
  width: 100%;
  padding: 5vh 0;
  max-width: ${breakpoints.md}px;
  margin: auto;
`;

export const PolicyContent = styled.div`
  margin-top: 50px;
  width: 100%;
  text-align: justify;
`;

export const PolicyParagraphTitle = styled.h3`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.subTitle};
  color: ${(props) => props.theme.text.primary};
`;

export const PolicyParagraph = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.bigText};
  color: ${(props) => props.theme.text.primary};
  line-height: 1.5em;
  margin-bottom: 15px;
`;

export const PolicyTable = styled.table`
  margin-top: 25px;
  margin-bottom: 50px;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${(props) => props.theme.text.primary};
  th,
  td {
    padding: 10px;
    border: 1px solid ${(props) => props.theme.text.primary};
    word-wrap: break-word;
    word-break: break-all;
    min-width: 80px;
  }
`;
