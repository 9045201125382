import styled from "styled-components";
import { devices } from "../../constants";

export const AppsideAnimationContainer = styled.div`
  height: auto;
  text-align: center;
  margin: 25px 0px 50px 0px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  @media only screen and ${devices.lg} {
    margin-top: -50px;
  }
  @media only screen and ${devices.xl} {
    margin-top: -25px;
  }
`;
