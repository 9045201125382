import styled from "styled-components";
import { Card as MuiCard, CardContent as MuiCardContent } from "@mui/material";

export const StyledCard = styled(MuiCard)`
  &.MuiCard-root {
    position:relative;
    padding: 0px;
    color: ${(props) => props.theme.text.primary};
    font-family: ${(props) => props.theme.font.primary};
    border-radius: 10px;
    box-shadow: ${(props) => props.theme.shadow.tertiary};
    background:${(props) => props.theme.bg.secondary};
    border: 1px solid ${(props) => props.theme.border.primary};
    }

    .MuiCardContent-root{
        padding: 25px 25px 25px 25px;
        text-align:justify;
    }

    .MuiCardActions-root{
        justify-content: center;
        padding-bottom: 50px;
    }
  }
`;

export const StyledCardContent = styled(MuiCardContent)`
  &.MuiCardContent-root {
    padding-bottom: 10px;
  }
`;

export const CardContent = styled.div``;

export const CardTitle = styled.div`
    font-size:${(props) => props.theme.fontSize.text};
    font-family: ${(props) => props.theme.font.primary};
    color:${(props) => props.theme.text.quaternary};
    text-align:center;
    margin:0;
    padding:15px 0;
    h2 {
    font-size:${(props) => props.theme.fontSize.text};
    font-family: ${(props) => props.theme.font.primary};
    color:${(props) => props.theme.text.quaternary};
      margin:0;
    }
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: ${(props) => props.theme.text.quaternary};
  border: 1px solid ${(props) => props.theme.border.tertiary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &.with-padding-top {
    padding-top: 15px;
  }
  padding-bottom: 25px;
`;
