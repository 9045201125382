import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { appsideMail } from "../../constants";
import { Link } from "../Link";
import { BigTitle, HeadlineContainer, SubTitle } from "./styles";

export const Headline: FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <HeadlineContainer>
      <BigTitle>{t("home.headline.title")}</BigTitle>
      <SubTitle>{t("home.headline.subtitle")}</SubTitle>

      <Link
        text={t("home.headline.link")}
        variant="secondary"
        to={`mailto:${appsideMail}`}
      />
    </HeadlineContainer>
  );
};
