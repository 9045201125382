import styled from "styled-components";
import { devices } from "../../constants";

export const ServiceRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px 0px;
  @media only screen and ${devices.lg} {
    flex-wrap: nowrap;
  }
  img {
    width: 300px;
    @media only screen and ${devices.sm} {
      width: 400px;
    }
  }
`;

export const ServiceRows = styled.div`
  border: 5px solid ${(props) => props.theme.border.quaternary};
  border-radius: 50px;
  box-shadow: ${(props) => props.theme.shadow.secondary};
  padding: 0px 25px;
  @media only screen and ${devices.sm} {
    padding: 0px 50px;
  }
  @media only screen and ${devices.lg} {
    padding: 50px;
  }
`;

export const CardContainer = styled.div`
  margin: 0;
  @media only screen and ${devices.lg} {
    margin: 0 50px;
  }
`;

export const CardText = styled.p`
  font-size: ${(props) => props.theme.fontSize.smallText};
  @media only screen and ${devices.md} {
    font-size: ${(props) => props.theme.fontSize.text};
  }
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

export const CardFooter = styled.div`
  height: 40px;
`;
