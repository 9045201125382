import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Menu from "../../components/Menu";
import { HeaderContainer } from "./styles";

export const Header = () => {
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 25) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderContainer className={scrolled ? "scrolled" : ""}>
      {/* <InfosContainer>
        <InfosLeft>
          <Logo />
        </InfosLeft>
        <InfosRight>
          <InfoIcon
            iconPosition="left"
            text={appsideMail}
            icon={<FontAwesomeIcon icon={faAt} />}
          />
          <InfoIcon
            iconPosition="left"
            text="+32 455 15 19 05"
            icon={<FontAwesomeIcon icon={faPhoneAlt} />}
          />
          <SocialContainer
            href="https://www.linkedin.com/company/appside-be"
            target="_blank"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </SocialContainer>
          <SocialContainer
            href="https://www.facebook.com/appsidetech"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </SocialContainer>
          {!isMobileView && <LanguageSelector />}
        </InfosRight>
      </InfosContainer> */}

      <Menu />
    </HeaderContainer>
  );
};
