import React from "react";
import { useTranslation } from "react-i18next";
import { AboutContent, AboutImg, AboutText, AboutTextContent } from "./styles";
import { SectionTitle } from "../../components/SectionTitle";
import { SectionContainer } from "../../components/SectionContainer";
import { FadeInSection } from "../../components/MotionSection";
import { useIsMobileView } from "../../hooks";

export const About = () => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  return (
    <SectionContainer id="appside-about">
      <FadeInSection direction={"left"} offset={50}>
        <SectionTitle title={t("about.title")} subtitle={t("about.subtitle")} />
      </FadeInSection>
      <AboutContent>
        <FadeInSection direction={"right"} offset={50} disabled={isMobileView}>
          <AboutImg src="assets/appside-team-1.png" alt="team-member-appside" />
          <AboutTextContent>
            <AboutText>{t("about.text1")}</AboutText>
            <AboutText>{t("about.text2")}</AboutText>
            <AboutText>{t("about.text3")}</AboutText>
            <AboutText>{t("about.text4")}</AboutText>
          </AboutTextContent>
        </FadeInSection>
      </AboutContent>
    </SectionContainer>
  );
};
