import styled from "styled-components";

export const SectionWrapper = styled.div`
  width: 100%;
  padding: 5vh 10vw;
  margin: 0 -10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${(props) => props.theme.font.primary};
`;
