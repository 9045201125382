import styled from "styled-components";
import { devices } from "../../constants";

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 75px;
`;

export const Title = styled.h1`
  width: fit-content;
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.mobileTitle};
  margin-bottom: 50px;
  margin-top: 0px;
  text-align: center;
  position: relative;
  @media only screen and ${devices.md} {
    font-size: ${(props) => props.theme.fontSize.title};
  }
  &:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 5px;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    background: ${(props) => props.theme.bg.tertiary};
    border-radius: 5px;
  }
`;

export const Subtitle = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.text};
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
`;
