import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

export type MotionSectionProps = {
  triggerAtStart?: boolean;
  direction: "top" | "left" | "right" | "none";
  children?: ReactNode;
  offset?: number;
  disabled?: boolean;
};

export const FadeInSection: FC<MotionSectionProps> = ({
  direction,
  triggerAtStart,
  children,
  offset = 100,
  disabled,
}): ReactElement => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: triggerAtStart ? 0 : 0.1,
  });
  const [initialPos, setInitalPos] = useState({ opacity: 0, x: 0, y: 0 });
  const [animationPos, setAniationPos] = useState({ opacity: 0, x: 0, y: 0 });

  useEffect(() => {
    if (direction === "top") {
      setInitalPos({ opacity: 0, y: offset, x: 0 });
    } else if (direction === "right") {
      setInitalPos({ opacity: 0, y: 0, x: -offset });
    } else if (direction === "left") {
      setInitalPos({ opacity: 0, y: 0, x: offset });
    } else {
      setInitalPos({ opacity: 0, y: 0, x: 0 });
    }
  }, [direction, offset]);

  useEffect(() => {
    if (direction === "top") {
      setAniationPos({
        opacity: inView ? 1 : 0,
        y: inView ? 0 : offset,
        x: 0,
      });
    } else if (direction === "right") {
      setAniationPos({
        opacity: inView ? 1 : 0,
        x: inView ? 0 : -offset,
        y: 0,
      });
    } else if (direction === "left") {
      setAniationPos({
        opacity: inView ? 1 : 0,
        x: inView ? 0 : offset,
        y: 0,
      });
    } else {
      setAniationPos({ opacity: inView ? 1 : 0, y: 0, x: 0 });
    }
  }, [direction, inView, offset]);

  if (disabled) {
    return <> {children}</>;
  }

  if (triggerAtStart) {
    return (
      <motion.div
        ref={ref}
        initial={{
          opacity: 0,
          x: direction === "right" ? -offset : 0,
          y: direction === "top" ? offset : 0,
        }}
        animate={animationPos}
        transition={{ duration: 1 }}
      >
        {children}
      </motion.div>
    );
  }

  return (
    <motion.div
      ref={ref}
      initial={initialPos}
      animate={animationPos}
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  );
};
