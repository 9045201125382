import styled from "styled-components";
import { devices } from "../../constants";

export const HomeHeadlineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and ${devices.md} {
    flex-wrap: nowrap;
  }
`;

export const HeadlineContainer = styled.div`
  width: 100%;
  padding: 25px 0;
  @media only screen and ${devices.md} {
    width: 50%;
    min-width: 300px;
  }
  @media only screen and ${devices.lg} {
    padding: 50px 0px;
    max-width: 500px;
  }
`;

export const AnimContainer = styled.div`
  width: 100%;
  @media only screen and ${devices.md} {
    width: 50%;
  }
  @media only screen and ${devices.lg} {
    width: 60%;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 0px;
  @media only screen and ${devices.md} {
    margin-top: 50px;
  }
  @media only screen and ${devices.xl} {
    flex-wrap: nowrap;
    margin-top: 50px;
  }
`;

export const CardItem = styled.div`
  padding: 12.5px;
  display: flex;
`;
