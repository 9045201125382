import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Logo } from "../../components/Logo";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { appsideMail } from "../../constants";
import {
  LegalLink,
  FooterCenter,
  FooterContainer,
  FooterLeft,
  FooterRight,
  FooterText,
  LegalAndLogoRows,
  SocialFooter,
} from "./styles";
import { Box } from "@mui/material";

import { CookieDialog } from "../../components/Dialog/cookieDialog";

export const Footer = () => {
  return (
    <FooterContainer>
      <CookieDialog />
      <FooterLeft>
        <FooterText>2022 – 2024 APPSIDE S.R.L.</FooterText>
        <FooterText>BE0794.486.022</FooterText>
        <FooterText>Rue de Longwy 30</FooterText>
        <FooterText>6790 Aubange</FooterText>
      </FooterLeft>
      <FooterCenter>
        <LegalAndLogoRows>
          <Logo white />
          <FooterText>Designed by Appside</FooterText>
        </LegalAndLogoRows>
        <LegalAndLogoRows>
          <LegalLink to={"/tems-of-service"}>C.G.V</LegalLink>
          <LegalLink to={"/cookie-policy"}>Cookies</LegalLink>
        </LegalAndLogoRows>
      </FooterCenter>
      <FooterRight>
        <Box sx={{ display: "flex" }}>
          <SocialFooter
            href="https://www.facebook.com/appsidetech"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </SocialFooter>
          <SocialFooter
            href="https://www.linkedin.com/company/appside-be"
            target="_blank"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </SocialFooter>
        </Box>
        <FooterText>{appsideMail}</FooterText>
        <FooterText>+32 455 15 19 05</FooterText>
      </FooterRight>
    </FooterContainer>
  );
};
