import React, { FC, ReactElement } from "react";
import {
  BigLogoImage,
  SmallLogoImage,
  MediumLogoImage,
  LargeLogoImage,
} from "./styles";

export type LogoProps = {
  white?: boolean;
};
export const Logo: FC<LogoProps> = (props): ReactElement => {
  const { white } = props;
  if (white)
    return (
      <SmallLogoImage
        src={`/assets/logos/logo-medium-white.png`}
        alt="Appside - Logo."
      />
    );
  return (
    <SmallLogoImage src={`/assets/logos/logo-big.png`} alt="Appside - Logo." />
  );
};

export const ReactLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/react-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - React Logo."
    />
  );
};

export const NetCoreLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/net-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Net Core Logo."
    />
  );
};

export const AzureLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/azure-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Azure Logo."
    />
  );
};

export const ElasticLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/elastic-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Elastic search Logo."
    />
  );
};

export const SwaggerLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/swagger-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Swagger Logo."
    />
  );
};

export const GitlabLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/gitlab-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Gitlab Logo."
    />
  );
};

export const GithubLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/github-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Github Logo."
    />
  );
};

export const BitbucketLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/bitbucket-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Bitbucket Logo."
    />
  );
};

export const SqlServerLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/sql-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Sql server Logo."
    />
  );
};

export const NodeJsLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/node-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Nodejs Logo."
    />
  );
};

export const RxJsLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/rxjs-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Rxjs Logo."
    />
  );
};

export const TypescriptLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/ts-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Typecript Logo."
    />
  );
};

export const JavascriptLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/js-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Javascript Logo."
    />
  );
};

export const Html5Logo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/html5-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Html5 Logo."
    />
  );
};

export const SplineLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/spline-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Spline Logo."
    />
  );
};

export const SitefinityLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/sitefinity-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Sitefinity Logo."
    />
  );
};

export const WordpressLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/wordpress-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Wordpress Logo."
    />
  );
};

export const VisualStudioLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/vs-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Visual studio Logo."
    />
  );
};

export const VisualStudioCodeLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/vs-code-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Visual studio code Logo."
    />
  );
};

export const LessLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/less-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Less Logo."
    />
  );
};

export const SassLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/sass-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Sass Logo."
    />
  );
};

export const ReduxLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/redux-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Redux Logo."
    />
  );
};

export const JestLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/jest-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Jest Logo."
    />
  );
};

export const MobxLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/mobx-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - MobX Logo."
    />
  );
};

export const ReactNativeLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/react-native-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - React native Logo."
    />
  );
};

export const XamarinLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/xamarin-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Xamarin Logo."
    />
  );
};

export const NpmLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/npm-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Npm Logo."
    />
  );
};
export const YarnLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/yarn-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Yarn Logo."
    />
  );
};
export const WebpackLogo: FC = (): ReactElement => {
  return (
    <LargeLogoImage
      src={`assets/technologies/webpack-tech.png`}
      alt="Appside - Développement d'applications web & mobiles - Webpack Logo."
    />
  );
};
