import styled from "styled-components";
import { devices } from "../../constants";
import { Link } from "react-router-dom";

export const HeaderHeight = "80px";
export const FooterHeight = "150px";
export const MenuHeight = "80px";
export const InfosHeight = "50px";

export const HeaderContainer = styled.div`
  z-index: 10;
  width: 100%;
  position: fixed;
  transition: all 0.5s ease;

  top: 0px;
  background: ${(props) => props.theme.bg.secondary};
  box-shadow: ${(props) => props.theme.shadow.primary};

  &.scrolled {
    top: 0px;
    background: ${(props) => props.theme.bg.secondary};
    box-shadow: ${(props) => props.theme.shadow.primary};
  }
`;

export const InfosContainer = styled.div`
  height: auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 0 5vw;
  @media only screen and ${devices.md} {
    flex-wrap: nowrap;
    height: ${InfosHeight};
    padding: 0 10vw;
  }
`;

export const SocialContainer = styled.a`
  display: none;
  @media only screen and ${devices.md} {
    height: 100%;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.text.quaternary};
    font-size: ${(props) => props.theme.fontSize.text};
    margin: 0px 10px;
  }
`;

export const SocialFooter = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: ${(props) => props.theme.text.secondary};
  font-size: ${(props) => props.theme.fontSize.text};
`;

export const InfosRight = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media only screen and ${devices.md} {
    justify-content: center;
    width: auto;
    margin: Opx;
  }
`;

export const InfosLeft = styled.div`
  display: none;
  @media only screen and ${devices.md} {
    justify-content: center;
    height: 100%;
    align-items: center;
    display: flex;
  }
`;

export const FooterContainer = styled.div`
  z-index: 10;
  background: ${(props) => props.theme.bg.quaternary};
  padding: 25px 10vw;
  @media only screen and ${devices.md} {
    display: flex;
    justify-content: space-between;
  }
`;

export const FooterLeft = styled.div`
  width: 100%;
  text-align: center;
  @media only screen and ${devices.md} {
    width: 30%;
    text-align: left;
  }
`;

export const FooterRight = styled.div`
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin: 5px 0;
    text-align: right;
  }
  @media only screen and ${devices.md} {
    width: 30%;
    justify-content: center;
    align-items: flex-end;
  }
`;

export const FooterCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px 0px;
  img {
    margin-right: 15px;
    width: 30px;
    height: auto;
  }
  @media only screen and ${devices.md} {
    width: 30%;
    padding: 0px;
  }
`;

export const FooterTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.text.secondary};
  font-family: ${(props) => props.theme.font.primary};
  text-transform: uppercase;
`;

export const FooterText = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.text.secondary};
  font-family: ${(props) => props.theme.font.primary};
  margin: 5px 0;
`;

export const LegalLink = styled(Link)`
  margin: 0 10px;
  color: ${(props) => props.theme.text.secondary};
  text-decoration: none;
  font-size: ${(props) => props.theme.fontSize.text};
  font-family: ${(props) => props.theme.font.primary};
`;

export const LegalAndLogoRows = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
