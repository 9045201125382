import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/Link";
import { Loader } from "../../components/Loader";
import { TechItem, TechItemTitle, TechItemLogo } from "./styles";

export type TechnoProps = {
  title: string;
  logo: JSX.Element;
  link: string;
};

export const TechnoItem = (props: TechnoProps) => {
  const { title, logo, link } = props;
  const { t } = useTranslation();
  return (
    <TechItem>
      <TechItemLogo>{logo}</TechItemLogo>
      <TechItemTitle>{title}</TechItemTitle>
      <Link
        text={t("technologies.button")}
        variant="secondary"
        to={link}
        external
      />
    </TechItem>
  );
};
