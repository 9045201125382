import styled from "styled-components";

export const CarouselWrapper = styled.div`
  padding-bottom: 50px;
  .slick-next:before,
  .slick-prev:before {
    z-index: 9;
    font-size: 50px;
    line-height: 1;
    opacity: 1;
    color: ${(props) => props.theme.bg.quaternary};
  }
  .slick-next {
    z-index: 9;
    right: 30px;
  }
  .slick-prev {
    z-index: 9;
    left: 0px;
  }
  .slick-dots {
    bottom: -75px;
  }
`;
