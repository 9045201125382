import React from "react";
import { motion } from "framer-motion";
import { useTheme } from "styled-components";
import { NormalLogoImage } from "../Logo/styles";

const containerVariants = {
  initial: {},
  animate: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const dotVariants = {
  initial: {},
  animate: {
    height: [20, 60, 20],
    transition: {
      duration: 1,
      repeat: Infinity,
    },
  },
};

type DotsWaveProps = {
  withLogo?: boolean;
};

export const DotsWave = (props: DotsWaveProps) => {
  const { withLogo } = props;
  const theme = useTheme();
  const colors = [
    theme.colors.primary,
    theme.colors.primary,
    theme.colors.primary,
    theme.colors.secondary,
    theme.colors.secondary,
    theme.colors.secondary,
  ];
  return (
    <div>
      {withLogo && (
        <NormalLogoImage
          src={`assets/logos/logo-big.png`}
          alt="logo"
          style={{ marginBottom: 25 }}
        />
      )}

      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        style={{
          display: "flex",
          gap: 16,
          height: 100,
          alignItems: "center",
        }}
      >
        {Array(6)
          .fill(null)
          .map((_, index) => {
            return (
              <motion.div
                key={index}
                variants={dotVariants}
                style={{
                  height: 20,
                  width: 20,
                  backgroundColor: colors[index % colors.length],
                  borderRadius: 20,
                }}
              />
            );
          })}
      </motion.div>
    </div>
  );
};
