import React, { FC, ReactElement } from "react";
import { StyledSubTitle, StyledTitle, TitleWrapper } from "./style";

export type TitleProps = {
  title: string;
  subtitle?: string;
  variant?: "primary" | "secondary";
};
export const Title: FC<TitleProps> = (props): ReactElement => {
  const { title, variant, subtitle } = props;
  return (
    <TitleWrapper>
      <StyledTitle className={variant}>{title}</StyledTitle>
      {subtitle && (
        <StyledSubTitle className={variant}>{subtitle}</StyledSubTitle>
      )}
    </TitleWrapper>
  );
};
