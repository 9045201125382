import React, { useEffect, useMemo } from "react";
import { ThemeProvider } from "styled-components";
import { Home } from "./modules/Home";
import { AppContainer, AppContentContainer } from "./styles";
import { defaultTheme } from "./theme";
import { Header } from "./modules/Layout/Header";
import { About } from "./modules/About";
import { Techno } from "./modules/Techno";
import { Services } from "./modules/Services";
import { Footer } from "./modules/Layout/Footer";
import { Contact } from "./modules/Contact";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SocialWidget } from "./components/SocialWidget";
import { Blog } from "./modules/Blog";
import { scrollToAnchor } from "./utils";
import { BlogPost } from "./modules/Blog/BlogPost";
import { NotFound } from "./components/Error/NotFound";
import { CookiesProvider } from "react-cookie";
import { CookiePolicy } from "./modules/Legal/CookiePolicy";
import { TermsOfService } from "./modules/Legal/TermsOfService";

export const App = () => {
  const queryClient = new QueryClient();

  const AppHome = () => (
    <>
      <Home />
      <Services />
      <Techno />
      <About />
      <Contact />
    </>
  );

  useEffect(() => {
    setTimeout(() => {
      scrollToAnchor();
    }, 500);

    window.addEventListener("hashchange", scrollToAnchor, false);

    return () => {
      window.removeEventListener("hashchange", scrollToAnchor, false);
    };
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AppContainer>
              <Header />
              <SocialWidget />
              <AppContentContainer>
                <Routes>
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/cookie-policy" element={<CookiePolicy />} />
                  <Route path="/tems-of-service" element={<TermsOfService />} />
                  <Route path="/blog/:slug" element={<BlogPost />} />
                  <Route path="/home" element={<AppHome />} />
                  <Route path="/" element={<AppHome />}>
                    <Route index element={<AppHome />} />
                  </Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </AppContentContainer>

              <Footer />
            </AppContainer>
          </BrowserRouter>
        </QueryClientProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
};
