import styled from "styled-components";

export const ButtonContainer = styled.div`
  button {
    text-decoration: none;
    background: ${(props) => props.theme.bg.tertiary};
    color: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.theme.fontSize.text};
    border: none;
    padding: 10px 35px;
    border-radius: 25px;
    text-transform: unset;
    font-family: ${(props) => props.theme.font.primary};
    box-shadow: ${(props) => props.theme.shadow.primary};
    &:hover {
      border: none;
      color: ${(props) => props.theme.text.secondary};
      background: ${(props) => props.theme.bg.quaternary};
    }
  }
`;
