import styled from "styled-components";
import { HeaderHeight } from "./modules/Layout/styles";
import { breakpoints, devices } from "./constants";

export const AppContainer = styled.div`
  min-height: 100vh;
  position: relative;
  background: ${(props) => props.theme.bg.primary};
  color: ${(props) => props.theme.text.primary};
  font-family: ${(props) => props.theme.font.primary};
  p,
  a {
    font-family: ${(props) => props.theme.font.primary};
  }
  overflow: hidden;
`;

export const AppContentContainer = styled.div`
  min-height: 100vh;
  color: ${(props) => props.theme.text.primary};
  font-family: ${(props) => props.theme.font.primary};
  p,
  a {
    font-family: ${(props) => props.theme.font.primary};
  }
  padding: 0 10vw;
  padding-top: 50px;
  max-width: ${breakpoints.xl}px;
  @media only screen and ${devices.md} {
    padding-top: ${HeaderHeight};
  }
  @media only screen and ${devices.xl} {
    margin: 0 auto;
  }
`;
