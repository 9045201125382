import { Link as MuiLink } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { LinkContainer } from "./styles";

export type LinkProps = {
  text: string;
  variant?: "primary" | "secondary";
  to?: string;
  external?: boolean;
  onClick?: () => void;
};
export const Link: FC<LinkProps> = (props): ReactElement => {
  const { text, variant, to, external, onClick } = props;

  return (
    <LinkContainer>
      <MuiLink
        href={to}
        className={variant ? variant : ""}
        target={external ? "_blank" : ""}
        rel={external ? "noopener" : ""}
        onClick={onClick}
      >
        {text}
      </MuiLink>
    </LinkContainer>
  );
};
