import styled from "styled-components";
import { breakpoints, devices } from "../../constants";
import { Link } from "../../components/Link";

export const BlogContainer = styled.div`
  width: 100%;
  padding: 5vh 0;
`;

export const WaveContainer = styled.div`
  z-index: 0;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 290px;
  top: 70vh;
  background: ${(props) => props.theme.bg.quaternary};
  svg {
    margin-top: -100%;
  }
  @media only screen and ${devices.md} {
    bottom: 140px;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
`;

export const PostsContainer = styled.div`
  width: 100%;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  @media only screen and ${devices.md} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
`;

export const PostItem = styled.div`
  margin-top: 25px;
  display: flex;
  @media only screen and ${devices.md} {
    margin-right: 25px;
  }
`;

export const PostHeader = styled.div`
  width: 100%;
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.smallText};
  text-align: left;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

export const PostCategoriesContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

export const PostCategory = styled.div`
  padding: 5px 25px;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.quaternary};
  margin-right: 25px;
  border-radius: 15px;
`;

export const PostFooter = styled.div`
  width: 100%;
  text-align: right;
`;

export const PostContent = styled.div`
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

export const PostTitleContainer = styled.h2`
  min-height: 35px;
  width: 100%;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5em;
`;

export const PostText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: ${(props) => props.theme.fontSize.text};
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.primary};
  line-height: 1.5em;
  p {
    margin: 0;
  }
`;

export const PostTags = styled.div`
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
`;
export const PostTag = styled.div`
  font-size: ${(props) => props.theme.fontSize.smallText};
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.primary};
  margin-right: 15px;
  margin-bottom: 10px;
  text-transform: uppercase;
  &:hover {
    color: ${(props) => props.theme.text.tertiary};
  }
`;

export const BlogPostContainer = styled.div`
  width: 100%;
  padding: 5vh 0;
  max-width: ${breakpoints.md}px;
  margin: auto;
`;

export const BlogPostTitle = styled.h1`
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.mobileTitle};
  @media only screen and ${devices.lg} {
    font-size: ${(props) => props.theme.fontSize.title};
  }
  margin: 0;
  margin-bottom: 25px;
`;

export const BlogPostImageContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top:50px;

  img {
    width: auto;
    max-width:100%;
    max-height: 600px;
    background:${(props) => props.theme.bg.secondary};
    }
  }
`;

export const BlogPostContent = styled.div`
  margin-top: 50px;
  width: 100%;
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.bigText};
  font-family: ${(props) => props.theme.font.primary};
  text-align: justify;
  line-height: 1.5em;
`;

export const BlogPostTag = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fontSize.text};
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.tertiary};
  margin-right: 15px;
  text-transform: uppercase;
  font-weight: bold;
`;

export const PostDate = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fontSize.text};
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.primary};
`;

export const BlogPostDate = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fontSize.text};
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.primary};
  opacity: 0.8;
`;

export const BlogPostCategoriesContainer = styled.div`
  display: flex;
  margin: 0;
  margin-bottom: 25px;
`;

export const BlogPostTags = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
`;

export const BlogPostCategory = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.quaternary};
  margin-right: 25px;
`;

export const BlogCategIcon = styled.div`
  svg {
    font-family: ${(props) => props.theme.font.primary};
    color: ${(props) => props.theme.text.quaternary};
    font-weight: bold;
    margin-right: 25px;
  }
`;

export const BlogPostAuthor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.text};
`;

export const BlogPostSocialNetwork = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const BlogPostSocialNetworkItem = styled.a`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 35px;
  width: 35px;
  background: ${(props) => props.theme.bg.tertiary};
  margin-left: 15px;
  border-radius: 50%;
  svg {
    color: ${(props) => props.theme.text.secondary};
    margin: 0;
  }
  &:hover {
    transform: scale(1.2);
  }
`;

export const BlogPostAuthorIcon = styled.div`
  display: none;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background: ${(props) => props.theme.bg.tertiary};
  margin-left: 15px;
  border-radius: 50%;
  svg {
    color: ${(props) => props.theme.text.secondary};
    margin: 0;
  }
  &:hover {
    transform: scale(1.2);
  }
  @media only screen and ${devices.md} {
    display: flex;
  }
`;

export const BlogPostShareContent = styled.div`
  margin: 50px 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.border.tertiary};
  border-bottom: 1px solid ${(props) => props.theme.border.tertiary};
`;

export const BlogPostExcerptContainer = styled.div`
  margin-top: 25px;
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.bigText};
  p {
    font-style: italic;
    text-align: justify;
    margin: 0;
    line-height: 1.5em;
  }
`;

export const PostLoarderContainer = styled.div`
  height: 40vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
