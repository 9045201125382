import { Card as MuiCard, CardMedia } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledCard,
  CardHeader,
  CardTitle,
  StyledCardContent,
  CardFooter,
  CardContent,
} from "./styles";

export type CardProps = {
  content?: JSX.Element | string;
  title?: JSX.Element | string;
  header?: JSX.Element;
  width?: number;
  height?: number;
  headerImg?: string;
  footer?: JSX.Element | string;
};
export const Card: FC<CardProps> = (props): ReactElement => {
  const { content, title, headerImg, width, height, header, footer } = props;
  const { t } = useTranslation();
  return (
    <StyledCard sx={{ minWidth: 100, width: width, height: height }}>
      {headerImg && <CardMedia image={headerImg} />}
      <StyledCardContent>
        {header && <CardHeader>{header}</CardHeader>}
        {title && (
          <CardTitle>
            {React.isValidElement(title) ? title : <h2>{title}</h2>}
          </CardTitle>
        )}
        {content && <CardContent>{content}</CardContent>}
      </StyledCardContent>
      {footer && (
        <CardFooter className={content ? "with-padding-top" : ""}>
          {footer}
        </CardFooter>
      )}
    </StyledCard>
  );
};
