import { useWindowSize } from "@uidotdev/usehooks";
import { breakpoints } from "./constants";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export const useIsMobileView = () => {
  const size = useWindowSize();
  const isMobileView = size && size.width && size.width <= breakpoints.sm;

  return !!isMobileView;
};

export const useIsTabletView = () => {
  const size = useWindowSize();
  const isTabletView = size && size.width && size.width <= breakpoints.xl;

  return !!isTabletView;
};

export const useIsSmallTabletView = () => {
  const size = useWindowSize();
  const isSmallTabletView = size && size.width && size.width <= breakpoints.md;

  return !!isSmallTabletView;
};

export const useIsLaptopView = () => {
  const size = useWindowSize();
  const isLaptopView = size && size.width && size.width <= breakpoints.xl;

  return !!isLaptopView;
};

export const useIsDesktopView = () => {
  const size = useWindowSize();
  const isDesktopView = size && size.width && size.width <= breakpoints.xxl;

  return !!isDesktopView;
};

export const useCurrentUrl = () => {
  const location = useLocation();
  const currentUrl =
    window.location.origin +
    location.pathname +
    location.search +
    location.hash;

  return currentUrl;
};

export const useBaseUrl = () => {
  const baseUrl = useMemo(() => {
    return `${window.location.protocol}//${window.location.host}`;
  }, []);
  return baseUrl;
};
