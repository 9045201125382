import React from "react";
import { Headline } from "../../components/Headline";
import {
  AnimContainer,
  CardItem,
  CardsContainer,
  HeadlineContainer,
  HomeHeadlineContainer,
} from "./styles";
import { Card } from "../../components/Card";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComputer,
  faLayerGroup,
  faMobileScreen,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { SectionContainer } from "../../components/SectionContainer";
import { AppAnimation } from "../../components/AppAnimation";
import { FadeInSection } from "../../components/MotionSection";
import { useIsMobileView, useIsTabletView } from "../../hooks";
import { Link } from "../../components/Link";
import { CardIcon } from "../../components/Card/styles";

export const Home = () => {
  const isMobileView = useIsMobileView();
  const { t } = useTranslation();

  return (
    <SectionContainer id='appside-home'>
      <HomeHeadlineContainer>
        <HeadlineContainer>
          <FadeInSection triggerAtStart direction={"right"}>
            <Headline />
          </FadeInSection>
        </HeadlineContainer>
        <AnimContainer>
          <AppAnimation />
        </AnimContainer>
      </HomeHeadlineContainer>
      <FadeInSection triggerAtStart direction='top' disabled={isMobileView}>
        <CardsContainer>
          <CardItem>
            <Card
              header={
                <CardIcon>
                  <FontAwesomeIcon icon={faComputer} size='xl' />
                </CardIcon>
              }
              title={t("home.servicesCard.web")}
              width={270}
              footer={
                <Link
                  text={t("services.button")}
                  variant='primary'
                  to={"#appside-web-service"}
                />
              }
            />
          </CardItem>
          <CardItem>
            <Card
              header={
                <CardIcon>
                  <FontAwesomeIcon icon={faStore} size='xl' />
                </CardIcon>
              }
              title={t("home.servicesCard.ecommerce")}
              width={270}
              footer={
                <Link
                  text={t("services.button")}
                  variant='primary'
                  to={"#appside-web-service"}
                />
              }
            />
          </CardItem>
          <CardItem>
            <Card
              header={
                <CardIcon>
                  <FontAwesomeIcon icon={faMobileScreen} size='xl' />
                </CardIcon>
              }
              title={t("home.servicesCard.mobile")}
              width={270}
              footer={
                <Link
                  text={t("services.button")}
                  variant='primary'
                  to={"#appside-mobile-service"}
                />
              }
            />
          </CardItem>
          <CardItem>
            <Card
              header={
                <CardIcon>
                  <FontAwesomeIcon icon={faLayerGroup} size='xl' />
                </CardIcon>
              }
              title={t("home.servicesCard.consulting")}
              width={270}
              footer={
                <Link
                  text={t("services.button")}
                  variant='primary'
                  to={"#appside-consulting-service"}
                />
              }
            />
          </CardItem>
        </CardsContainer>
      </FadeInSection>
    </SectionContainer>
  );
};
