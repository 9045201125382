import { Box, Stack, FormControl } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "../../components/TextField";
import { SectionTitle } from "../../components/SectionTitle";
import { ContactContainer } from "./styles";
import { Button } from "../../components/Button";
import { ContactMessage } from "../../models/contact.models";
import { Dialogs } from "../../components/Dialog";
import { FadeInSection } from "../../components/MotionSection";
import { useIsMobileView } from "../../hooks";
import { useSendMail } from "../../network/mutations";

export const Contact = () => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const [open, setOpen] = React.useState(false);
  const [isEmailSended, setIsEmailSended] = React.useState(false);
  const { mutate: sendMail, isLoading, isError, isSuccess } = useSendMail();

  useEffect(() => {
    if (isSuccess) {
      console.log("Email sent successfully!");
      setOpen(true);
      setIsEmailSended(true);
    } else if (isError) {
      console.error("Error while sending the email");
      setOpen(true);
      setIsEmailSended(false);
    }
  }, [isError, isSuccess]);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const mail: ContactMessage = {
      firstName: event.target["firstname"].value,
      lastName: event.target["lastname"].value,
      email: event.target["email"].value,
      message: event.target["message"].value,
    };

    sendMail(mail);
  };

  return (
    <ContactContainer id="appside-contact">
      <FadeInSection direction={"right"} offset={50}>
        <SectionTitle title={t("contact.title")} subtitle={t("contact.text")} />
      </FadeInSection>
      <FadeInSection direction={"left"} offset={50} disabled={isMobileView}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          autoComplete="off"
          sx={{ maxWidth: "600px", width: "100%" }}
        >
          <Stack
            spacing={2}
            direction={isMobileView ? "column" : "row"}
            sx={{ mb: 2 }}
          >
            <FormControl
              sx={isMobileView ? { width: "100%" } : { width: "50%" }}
            >
              <TextField
                id="lastname"
                variant="outlined"
                label="Nom"
                required
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl
              sx={isMobileView ? { width: "100%" } : { width: "50%" }}
            >
              <TextField
                id="firstname"
                variant="outlined"
                label="Prénom"
                required
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Stack>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="email"
              variant="outlined"
              type={"email"}
              label="Email"
              required
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 5 }}>
            <TextField
              id="message"
              variant="outlined"
              type={"textarea"}
              multiline
              rows={7}
              maxRows={7}
              label="Message"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <Box sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              text={
                isEmailSended
                  ? t("contact.buttonAlreadySent")
                  : t("contact.button")
              }
              isLoading={isLoading}
              disabled={isEmailSended}
            />
          </Box>
        </Box>
      </FadeInSection>
      <Dialogs
        open={open}
        handleClose={() => setOpen(false)}
        title={
          isEmailSended
            ? t("contact.dialog.successTitle")
            : t("contact.dialog.errorTitle")
        }
        text={
          isEmailSended
            ? t("contact.dialog.successText")
            : t("contact.dialog.errorText")
        }
      />
    </ContactContainer>
  );
};
