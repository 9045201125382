import styled from "styled-components";
import { devices } from "../../constants";

export const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 50px;
  margin-top: 25px;
`;

export const StyledTitle = styled.h1`
  width: 100%;
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.mobileTitle};
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
  &.secondary {
    @media only screen and ${devices.md} {
      text-align: left;
    }
  }
  @media only screen and ${devices.md} {
    font-size: ${(props) => props.theme.fontSize.title};
  }
`;

export const StyledSubTitle = styled.h2`
  width: 100%;
  font-weight: normal;
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.subTitle};
  margin-bottom: 0px;
  margin-top: 10px;
  text-align: center;
  &.secondary {
    @media only screen and ${devices.md} {
      text-align: left;
    }
  }
  @media only screen and ${devices.md} {
    font-size: ${(props) => props.theme.fontSize.mobileTitle};
  }
`;
