import { format } from "date-fns";

export const scrollToAnchor = () => {
  const hash = window.location.hash;
  if (hash) {
    const id = hash.replace("#", "");
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
};

export const transformPostDate = (postDate: string) => {
  const date: Date = new Date(postDate);
  const formattedDate: string = format(date, "dd MMMM yyy");
  return formattedDate;
};
