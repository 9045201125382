import styled from "styled-components";
import { devices } from "../../constants";

export const TechItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
`;

export const TechItemTitle = styled.h2`
  color: ${(props) => props.theme.text.primary};
  font-size: 20px;
  text-align: center;
  margin-top: 0px;
  padding-bottom: 15px;
`;

export const TechItemLogo = styled.div`
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;

  img {
    width: 150px;
    height: auto;
    @media only screen and ${devices.md} {
      width: 200px;
    }
  }
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`;
