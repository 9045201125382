import React, { FC, ReactElement } from "react";
import { SocialItem, SocialWidgetWrapper } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export const SocialWidget = (): ReactElement => {
  return (
    <SocialWidgetWrapper>
      <SocialItem href="https://www.facebook.com/appsidebe" target="_blank">
        <FontAwesomeIcon icon={faFacebook} size="lg" />
      </SocialItem>
      <SocialItem
        href="https://www.linkedin.com/company/appside-be"
        target="_blank"
      >
        <FontAwesomeIcon icon={faLinkedin} size="lg" />
      </SocialItem>
    </SocialWidgetWrapper>
  );
};
