import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@uidotdev/usehooks";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ActiveItem,
  Item,
  MenuActions,
  MenuItems,
  MenuWrapper,
  MobileMenuItems,
} from "./styles";
import { Logo } from "../Logo";
import { Link } from "../Link";
import { useLocation, useNavigate } from "react-router-dom";
import { useBaseUrl, useIsMobileView } from "../../hooks";

type MenuItemProps = {
  title: string;
  active?: boolean;
  to?: string;
};

const Menu: FC = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [activeKey, setActiveKey] = useState("appside-home");
  const size = useWindowSize();
  const canDisplayButton = size && size.width && size.width >= 1100;
  const isMobileView = useIsMobileView();
  const location = useLocation();
  const baseUrl = useBaseUrl();

  const MenuItem = (props: MenuItemProps) => {
    const { title, active, to } = props;
    if (active) {
      return (
        <ActiveItem href={to} onClick={isMobileView ? onMenuClick : undefined}>
          {title}
        </ActiveItem>
      );
    }
    return (
      <Item href={to} onClick={isMobileView ? onMenuClick : undefined}>
        {title}
      </Item>
    );
  };

  const handleScroll = () => {
    const positions = {
      home: 0,
      services: document.getElementById("appside-services")?.offsetTop,
      technologies: document.getElementById("appside-tech")?.offsetTop,
      about: document.getElementById("appside-about")?.offsetTop,
      contact: document.getElementById("appside-contact")?.offsetTop,
    };

    const scrollPosition = window.scrollY + 400;

    if (positions.contact && scrollPosition >= positions.contact) {
      setActiveKey("appside-contact");
    } else if (positions.about && scrollPosition >= positions.about) {
      setActiveKey("appside-about");
    } else if (
      positions.technologies &&
      scrollPosition >= positions.technologies
    ) {
      setActiveKey("appside-tech");
    } else if (positions.services && scrollPosition >= positions.services) {
      setActiveKey("appside-services");
    } else {
      setActiveKey("appside-home");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/blog")) {
      setActiveKey("appside-blog");
    } else {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [location, handleScroll]);

  const onMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const items = [
    <MenuItem
      title={t("menu.home")}
      active={activeKey === "appside-home"}
      to={`${baseUrl}#appside-home`}
    />,
    <MenuItem
      title={t("menu.services")}
      active={activeKey === "appside-services"}
      to={`${baseUrl}#appside-services`}
    />,

    <MenuItem
      title={t("menu.technologies")}
      active={activeKey === "appside-tech"}
      to={`${baseUrl}#appside-tech`}
    />,
    <MenuItem
      title={t("menu.about")}
      active={activeKey === "appside-about"}
      to={`${baseUrl}#appside-about`}
    />,

    <MenuItem
      title={t("menu.blog")}
      active={activeKey === "appside-blog"}
      to={`${baseUrl}/blog`}
    />,
  ];

  return (
    <MenuWrapper className={isOpen ? "open" : "close"}>
      {isMobileView && (
        <MenuActions>
          <Logo />
          {!isOpen ? (
            <FontAwesomeIcon icon={faBars} size="xl" onClick={onMenuClick} />
          ) : (
            <FontAwesomeIcon icon={faTimes} size="xl" onClick={onMenuClick} />
          )}
        </MenuActions>
      )}

      {isMobileView ? (
        <MobileMenuItems className={isOpen ? "open" : "close"}>
          {...items}
          <MenuItem
            title={t("menu.contact")}
            active={activeKey === "appside-contact"}
            to={`${baseUrl}#appside-contact`}
          />
        </MobileMenuItems>
      ) : (
        <MenuItems>
          <Logo />
          {...items}
        </MenuItems>
      )}

      {canDisplayButton && (
        <Link
          text={t("header.button")}
          to={`${baseUrl}#appside-contact`}
          variant="secondary"
        />
      )}
    </MenuWrapper>
  );
};
export default Menu;
