import React, { FC, ReactElement, Suspense, useEffect, useState } from "react";
import { DotsWave } from "../Loader/motionLoader";

import { AppsideAnimationContainer } from "./styles";
import {
  useIsDesktopView,
  useIsLaptopView,
  useIsMobileView,
  useIsSmallTabletView,
  useIsTabletView,
} from "../../hooks";
const Spline = React.lazy(() => import("@splinetool/react-spline"));

export const AppAnimation: FC = (): ReactElement => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSplineLoaded, setIsSplineLoaded] = useState(false);
  const isMobileView = useIsMobileView();
  const isTabletView = useIsTabletView();
  const isLaptopView = useIsLaptopView();
  const isSmallTabletView = useIsSmallTabletView();
  const isDesktopView = useIsDesktopView();

  const getViewSize = () => {
    if (isMobileView) return 350;
    else if (isSmallTabletView) return 500;
    else if (isTabletView) return 700;
    else if (isLaptopView) return 550;
    else if (isDesktopView) return 800;
    else return 900;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSplineLoaded(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <AppsideAnimationContainer
        style={{
          width: "auto",
        }}
      >
        {isTabletView && (
          <img
            src={"/assets/appside-design.png"}
            width={"100%"}
            style={{ maxWidth: getViewSize() }}
            alt="Appside - Studio de développement d'applications web & mobiles."
          />
        )}

        {(isLoading || !isSplineLoaded) && !isTabletView && <DotsWave />}
        {!isTabletView && isSplineLoaded && (
          <Suspense>
            <Spline
              scene="https://prod.spline.design/dA0KRCITEF5GxWfK/scene.splinecode"
              onLoad={() => setIsLoading(false)}
              style={{
                width: !isDesktopView ? 900 : undefined,
                display: isLoading ? "none" : "block",
              }}
            />
          </Suspense>
        )}
      </AppsideAnimationContainer>
    </>
  );
};
