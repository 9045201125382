import React from "react";
import {
  PolicyContainer,
  PolicyContent,
  PolicyParagraph,
  PolicyParagraphTitle,
  PolicyTitle,
} from "./styles";
import { useTranslation } from "react-i18next";

export const TermsOfService = () => {
  const { t } = useTranslation();
  return (
    <PolicyContainer>
      <PolicyTitle>{t("termsOfService.title")}</PolicyTitle>
      <PolicyContent>
        <PolicyParagraphTitle>
          {t("termsOfService.object.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>{t("termsOfService.object.p1")}</PolicyParagraph>
        <PolicyParagraph>{t("termsOfService.object.p2")}</PolicyParagraph>
        <PolicyParagraphTitle>
          {t("termsOfService.priority.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>{t("termsOfService.priority.p1")}</PolicyParagraph>
        <PolicyParagraphTitle>
          {t("termsOfService.definition.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>
          <b>{t("termsOfService.definition.p1.title")}</b>
          {t("termsOfService.definition.p1.text")}
        </PolicyParagraph>
        <PolicyParagraph>
          <b>{t("termsOfService.definition.p2.title")}</b>
          {t("termsOfService.definition.p2.text")}
        </PolicyParagraph>
        <PolicyParagraph>
          <b>{t("termsOfService.definition.p3.title")}</b>
        </PolicyParagraph>
        <PolicyParagraph>
          {t("termsOfService.definition.p3.text1")}
        </PolicyParagraph>
        <PolicyParagraph>
          {t("termsOfService.definition.p3.text2")}
        </PolicyParagraph>
        {Array.from({ length: 10 }).map((_, index) => (
          <React.Fragment key={index}>
            <PolicyParagraph>
              <b>{t(`termsOfService.definition.p${index + 4}.title`)}</b>
            </PolicyParagraph>
            <PolicyParagraph>
              {t(`termsOfService.definition.p${index + 4}.text`)}
            </PolicyParagraph>
          </React.Fragment>
        ))}
        <PolicyParagraphTitle>
          {t("termsOfService.mission.title")}
        </PolicyParagraphTitle>
        {Array.from({ length: 4 }).map((_, index) => (
          <React.Fragment key={index}>
            <PolicyParagraph>
              {t(`termsOfService.mission.p${index + 1}`)}
            </PolicyParagraph>
          </React.Fragment>
        ))}
        <PolicyParagraphTitle>
          {t("termsOfService.confidentiality.title")}
        </PolicyParagraphTitle>
        {Array.from({ length: 12 }).map((_, index) => (
          <React.Fragment key={index}>
            <PolicyParagraph>
              {t(`termsOfService.confidentiality.p${index + 1}`)}
            </PolicyParagraph>
          </React.Fragment>
        ))}
        <PolicyParagraphTitle>
          {t("termsOfService.dataProtection.title")}
        </PolicyParagraphTitle>
        {Array.from({ length: 2 }).map((_, index) => (
          <React.Fragment key={index}>
            <PolicyParagraph>
              {t(`termsOfService.dataProtection.p${index + 1}`)}
            </PolicyParagraph>
          </React.Fragment>
        ))}
        <PolicyParagraphTitle>
          {t("termsOfService.intellectualProperty.title")}
        </PolicyParagraphTitle>
        {Array.from({ length: 4 }).map((_, index) => (
          <React.Fragment key={index}>
            <PolicyParagraph>
              {t(`termsOfService.intellectualProperty.p${index + 1}`)}
            </PolicyParagraph>
          </React.Fragment>
        ))}
        <PolicyParagraphTitle>
          {t("termsOfService.control.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>{t(`termsOfService.control.p1`)}</PolicyParagraph>
        <PolicyParagraphTitle>
          {t("termsOfService.billing.title")}
        </PolicyParagraphTitle>
        {Array.from({ length: 8 }).map((_, index) => (
          <React.Fragment key={index}>
            <PolicyParagraph>
              {t(`termsOfService.billing.p${index + 1}`)}
            </PolicyParagraph>
          </React.Fragment>
        ))}
        <PolicyParagraphTitle>
          {t("termsOfService.lawAndRegulation.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>
          {t(`termsOfService.lawAndRegulation.p1`)}
        </PolicyParagraph>
        <PolicyParagraphTitle>
          {t("termsOfService.responsabilityLimit.title")}
        </PolicyParagraphTitle>
        {Array.from({ length: 6 }).map((_, index) => (
          <React.Fragment key={index}>
            <PolicyParagraph>
              {t(`termsOfService.responsabilityLimit.p${index + 1}`)}
            </PolicyParagraph>
          </React.Fragment>
        ))}
        <PolicyParagraphTitle>
          {t("termsOfService.compensation.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>{t(`termsOfService.compensation.p1`)}</PolicyParagraph>
        <PolicyParagraphTitle>
          {t("termsOfService.resiliation.title")}
        </PolicyParagraphTitle>
        {Array.from({ length: 6 }).map((_, index) => (
          <React.Fragment key={index}>
            <PolicyParagraph>
              {t(`termsOfService.resiliation.p${index + 1}`)}
            </PolicyParagraph>
          </React.Fragment>
        ))}
        <PolicyParagraphTitle>
          {t("termsOfService.applicableLaw.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>
          {t(`termsOfService.applicableLaw.p1`)}
        </PolicyParagraph>
        <PolicyParagraphTitle>
          {t("termsOfService.forceMajeur.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>{t(`termsOfService.forceMajeur.p1`)}</PolicyParagraph>
        <PolicyParagraphTitle>
          {t("termsOfService.other.title")}
        </PolicyParagraphTitle>
        {Array.from({ length: 5 }).map((_, index) => (
          <React.Fragment key={index}>
            <PolicyParagraph>
              {t(`termsOfService.other.p${index + 1}`)}
            </PolicyParagraph>
          </React.Fragment>
        ))}
      </PolicyContent>
    </PolicyContainer>
  );
};
