import React, { FC, ReactElement } from "react";
import { ErrorContainer } from "./styles";

export const NotFound: FC = (): ReactElement => {
  return (
    <ErrorContainer>
      <img src="/assets/errors/404.svg" alt="Oops 404 no found" />
    </ErrorContainer>
  );
};
