import { useMutation, UseMutationResult } from "react-query";
import { fetchApi } from ".";
import { ContactMessage } from "../models/contact.models";

export const useSendMail = () => {
  const data = {
    service_id: "service_xbtmb9f",
    template_id: "template_o08wusp",
    user_id: "9_ZrZPBvWaUeIaNeH",
  };
  return useMutation({
    mutationFn: async (mail: ContactMessage) =>
      await fetchApi(`https://api.emailjs.com/api/v1.0/email/send`, "POST", {
        ...data,
        template_params: mail,
      }),
  });
};
