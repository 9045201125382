import React from "react";
import {
  PolicyContainer,
  PolicyContent,
  PolicyParagraph,
  PolicyParagraphTitle,
  PolicyTable,
  PolicyTitle,
} from "./styles";
import { useTranslation } from "react-i18next";

export const CookiePolicy = () => {
  const { t } = useTranslation();
  return (
    <PolicyContainer>
      <PolicyTitle>{t("cookies.policy.header")}</PolicyTitle>
      <PolicyContent>
        <PolicyParagraph>{t("cookies.policy.introduction")}</PolicyParagraph>
        <PolicyParagraphTitle>
          {t("cookies.policy.whatAreCookies.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>
          {t("cookies.policy.whatAreCookies.text")}
        </PolicyParagraph>
        <PolicyParagraphTitle>
          {t("cookies.policy.whyUseCookies.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>
          {t("cookies.policy.whyUseCookies.text")}
        </PolicyParagraph>
        <PolicyTable>
          <tr>
            <th> {t("cookies.policy.PolicyTable.cookieName")}</th>
            <th> {t("cookies.policy.PolicyTable.description")}</th>
            <th> {t("cookies.policy.PolicyTable.lifetime")}</th>
          </tr>
          <tr>
            <td>_ga</td>
            <td>{t("cookies.policy.PolicyTable.ga")}</td>
            <td>2 ans</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>{t("cookies.policy.PolicyTable.gid")}</td>
            <td>24 heures</td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>{t("cookies.policy.PolicyTable.gat")}</td>
            <td>1 minute</td>
          </tr>
          <tr>
            <td>ga#</td>
            <td>{t("cookies.policy.PolicyTable.gat#")}</td>
            <td>2 ans</td>
          </tr>
        </PolicyTable>
        <PolicyParagraphTitle>
          {t("cookies.policy.controlCookies.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>
          {t("cookies.policy.controlCookies.text")}
        </PolicyParagraph>
        <PolicyTable>
          <tr>
            <th> {t("cookies.policy.PolicyTable.browser")}</th>
            <th> {t("cookies.policy.PolicyTable.link")}</th>
          </tr>
          <tr>
            <td>Firefox</td>
            <td>
              <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">
                https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
              </a>
            </td>
          </tr>
          <tr>
            <td>Chrome</td>
            <td>
              <a href="https://support.google.com/chrome/answer/95647?hl=en">
                https://support.google.com/chrome/answer/95647?hl=en
              </a>
            </td>
          </tr>
          <tr>
            <td>Safari</td>
            <td>
              <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
              </a>
            </td>
          </tr>
          <tr>
            <td>Internet explorer </td>
            <td>
              <a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
              </a>
            </td>
          </tr>
          <tr>
            <td>Firefox (mobile) </td>
            <td>
              <a href="https://support.mozilla.org/en-US/kb/clear-your-browsing-history-and-other-personal-data">
                https://support.mozilla.org/en-US/kb/clear-your-browsing-history-and-other-personal-data
              </a>
            </td>
          </tr>
          <tr>
            <td>Chrome (mobile) </td>
            <td>
              <a href="https://support.google.com/chrome/answer/95647?hl=en">
                https://support.google.com/chrome/answer/95647?hl=en
              </a>
            </td>
          </tr>
          <tr>
            <td>Safari (mobile) </td>
            <td>
              <a href="https://support.apple.com/en-us/HT201265">
                https://support.apple.com/en-us/HT201265
              </a>
            </td>
          </tr>
          <tr>
            <td>Microsoft Edge (mobile) </td>
            <td>
              <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
              </a>
            </td>
          </tr>
        </PolicyTable>
        <PolicyParagraphTitle>
          {t("cookies.policy.dataSecurity.title")}
        </PolicyParagraphTitle>
        <PolicyParagraph>
          {t("cookies.policy.dataSecurity.text")}
        </PolicyParagraph>
      </PolicyContent>
    </PolicyContainer>
  );
};
