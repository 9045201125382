import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  Slide,
  DialogContent,
  Button,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledDialogTitle,
  StyledDialogContentText,
  StyledDialogActions,
} from "./styles";

export interface DialogProps extends MuiDialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  text: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Dialogs: FC<DialogProps> = (props): ReactElement => {
  const { open, handleClose, title, text, ...rest } = props;
  const { t } = useTranslation();
  return (
    <MuiDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      {...rest}
    >
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText>{text}</StyledDialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <Button onClick={handleClose}>{t("contact.dialog.action")}</Button>
      </StyledDialogActions>
    </MuiDialog>
  );
};
