import styled from "styled-components";
import { breakpoints, devices } from "../../constants";
import { MenuHeight } from "../../modules/Layout/styles";

const buttonHoverProperties = `
  content: "";
  position: absolute;
  height: 3px;
  bottom: -10px;
  width: 100%;
  left: 0;
  border-radius: 5px;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: transform 0.4s;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10vw;

  @media only screen and ${devices.lg} {
    height: ${MenuHeight};
    flex-direction: row;
    padding: 0px 10vw;
  }

  &.open {
    background: ${(props) => props.theme.bg.secondary};
  }
  max-width: ${breakpoints.xl}px;
  @media only screen and ${devices.xl} {
    margin: 0 auto;
  }
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  img {
    margin-right: 50px;
  }
`;

export const MobileMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  background-color: ${(props) => props.theme.bg.secondary};
  opacity: 0;
  height: 0;
  overflow: hidden;
  img {
    margin-right: 50px;
  }
  transition: height 1s ease, opacity 0.3s ease;
  &.open {
    padding: 25px 0px;
    opacity: 1;
    height: 250px;
    width: 100%;
    background-color: ${(props) => props.theme.bg.secondary};
    transition: height 0.5s ease, opacity 2s ease;
  }
  &.close {
    opacity: 0;
    height: 0;
    transition: height 1s ease, opacity 0.3s ease;
  }
`;

export const MenuActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.text.primary};
`;

export const Item = styled.a`
    text-decoration: none;
    background-color: transparent;
    font-weight: inherit;
    line-height: inherit; 
    width: fit-content;
    color: ${(props) => props.theme.text.primary};
    font-size:${(props) => props.theme.fontSize.menu};
    position:relative;
    cursor: pointer;
    margin-right: 50px;
    text-align: left;
    
    &:before {
      ${buttonHoverProperties}
      background: ${(props) => props.theme.bg.tertiary};
    }
    &:hover, &.active{
      font-weight:bold;
      &:before {
        ${buttonHoverProperties}
        background: ${(props) => props.theme.bg.tertiary};
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  );
`;

export const ActiveItem = styled(Item)`
  font-weight: bold;
  &:before {
    ${buttonHoverProperties}
    background: ${(props) => props.theme.bg.tertiary};
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
`;
