import styled from "styled-components";
import { devices } from "../../constants";

export const AboutTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.bg.quaternary};
  padding: 25px;
  border-radius: 50px;
  margin-top: 25px;
  @media only screen and ${devices.md} {
    padding-left: 150px;
    margin-left: 100px;
    margin-top: -150px;
    padding: 50px;
  }
  @media only screen and ${devices.lg} {
    padding-left: 200px;
    margin-left: 200px;
    margin-top: -250px;
  }
`;
export const AboutContent = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and ${devices.md} {
    display: block;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AboutText = styled.p`
  color: ${(props) => props.theme.text.secondary};
  font-size: ${(props) => props.theme.fontSize.text};
  width: 100%;
  text-align: justify;
`;

export const AboutImg = styled.img`
  height: 250px;
  width: 250px;
  padding: 25px 25px 0 25px;
  background-color: ${(props) => props.theme.bg.tertiary};
  border-radius: 50px;
  @media only screen and ${devices.md} {
    border-radius: 0 50px;
    height: 150px;
    width: 150px;
  }
  @media only screen and ${devices.lg} {
    height: 250px;
    width: 250px;
    padding: 50px 50px 0 50px;
  }
`;
