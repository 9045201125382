import React, { FC, ReactElement } from "react";
import { Subtitle, Title, TitleWrapper } from "./style";

export type SectionTitleProps = {
  title: string;
  subtitle?: string;
};
export const SectionTitle: FC<SectionTitleProps> = (props): ReactElement => {
  const { title, subtitle } = props;
  return (
    <TitleWrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TitleWrapper>
  );
};
