import React, { FC, ReactElement, ReactNode } from "react";
import { SectionWrapper } from "./style";

export type SectionContainerProps = {
  id: string;
  children?: ReactNode;
};
export const SectionContainer: FC<SectionContainerProps> = (
  props
): ReactElement => {
  const { id, children } = props;
  return <SectionWrapper id={id}>{children}</SectionWrapper>;
};
