export const defaultTheme = {
  main: "mediumseagreen",
  bg: {
    primary: "#E1E3F9",
    secondary: "#eeeffa",
    tertiary: "#EA1C3F",
    quaternary: "#06204F",
  },
  text: {
    primary: "#091222",
    secondary: "#ffffff",
    tertiary: "#EA1C3F",
    quaternary: "#06204F",
  },
  border: {
    primary: "#C4C4C4",
    secondary: "#EA1C3F",
    tertiary: "#06204F",
  },
  colors: {
    primary: "#EA1C3F",
    secondary: "#06204F",
  },
  font: {
    primary: '"Montserrat", "sans-serif;"',
  },
  fontSize: {
    title: "40px",
    mobileTitle: "30px",
    subTitle: "18px",
    bigText: "16px",
    text: "14px",
    menu: "14px",
    smallText: "12px",
  },
  spaces: {
    sectionLR: "10vw",
    sectionTB: "50px",
  },
  shadow: {
    primary: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;",
    secondary: "rgba(0, 0, 0, 0.5) 0px 5px 35px 0px",
    tertiary: "rgba(0, 0, 0, 0.1) 0px 2px 15px 0px",
  },
};
