import {
  Dialog,
  DialogProps as MuiDialogProps,
  Slide,
  DialogContent,
  Button,
} from "@mui/material";
import { faCookieBite } from "@fortawesome/free-solid-svg-icons";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledDialogTitle,
  StyledDialogContentText,
  StyledCookieDialogActions,
  StyledDialogContainer,
  CookieLink,
} from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CookieDialog = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(["cookiesAccepted"]);
  const [openDialog, setOpenDialog] = useState(false);

  const loadGoogleAnalytics = () => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-4621E5LCSP";
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag =
      window.gtag ||
      function () {
        window.dataLayer.push(arguments);
      };

    window.gtag("js", new Date());
    window.gtag("config", "G-4621E5LCSP", { send_page_view: true });
  };

  useEffect(() => {
    const shouldOpenDialog = cookies.cookiesAccepted === undefined;
    setOpenDialog(shouldOpenDialog);

    if (cookies.cookiesAccepted === true) {
      loadGoogleAnalytics();
      window["ga-disable-G-4621E5LCSP"] = false;
    } else {
      window["ga-disable-G-4621E5LCSP"] = true;
    }
  }, [cookies.cookiesAccepted]);

  const handleAccept = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 90);
    setCookie("cookiesAccepted", "true", { path: "/", expires });
    setOpenDialog(false);
  };

  const handleClose = () => {
    setCookie("cookiesAccepted", "false", { path: "/" });
    setOpenDialog(false);
  };

  return (
    <StyledDialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
    >
      <StyledDialogTitle>
        <FontAwesomeIcon icon={faCookieBite} />
        <span> {t("cookies.title")}</span>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText>
          {t("cookies.text")}
          <CookieLink to={`/cookie-policy`}> Cookie Policy </CookieLink>
        </StyledDialogContentText>
      </DialogContent>
      <StyledCookieDialogActions>
        <Button onClick={handleClose} color="secondary">
          {t("cookies.button.deny")}
        </Button>
        <Button onClick={handleAccept} color="primary">
          {t("cookies.button.accept")}
        </Button>
      </StyledCookieDialogActions>
    </StyledDialogContainer>
  );
};
