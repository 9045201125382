export async function fetchApi<TRequest, TResponse>(
  url: string,
  method: "GET" | "POST" = "GET",
  body?: TRequest
): Promise<TResponse | string> {
  const requestOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw new Error(`Error during the request: ${response.statusText}`);
  }

  const contentType = response.headers.get("Content-Type");
  if (contentType && contentType.includes("application/json")) {
    const responseData: TResponse = await response.json();
    return responseData;
  } else {
    const responseText: string = await response.text();
    return responseText;
  }
}
