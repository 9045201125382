import { CircularProgress, Link as MuiLink } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { LoaderContainer } from "./styles";

export type LoaderProps = {
  size?: "small" | "medium" | "big";
  variant?: "primary" | "secondary";
};
export const Loader: FC<LoaderProps> = (props): ReactElement => {
  const { variant } = props;

  return (
    <LoaderContainer className={variant ? variant : ""}>
      <CircularProgress />
    </LoaderContainer>
  );
};
