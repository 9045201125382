import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../components/Card";
import { SectionTitle } from "../../components/SectionTitle";
import {
  CardContainer,
  CardFooter,
  CardText,
  ServiceRow,
  ServiceRows,
} from "./styles";
import { SectionContainer } from "../../components/SectionContainer";
import { FadeInSection } from "../../components/MotionSection";
import { useIsMobileView, useIsTabletView } from "../../hooks";
import { Link } from "../../components/Link";
import { appsideMail } from "../../constants";

export const Services = () => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const isTabletView = useIsTabletView();

  return (
    <SectionContainer id="appside-services">
      <FadeInSection direction={"left"} offset={50}>
        <SectionTitle
          title={t("services.title")}
          subtitle={t("services.subtitle")}
        />
      </FadeInSection>
      <ServiceRows>
        <ServiceRow>
          <FadeInSection direction="top" offset={50}>
            <img
              src="assets/services/service-web.png"
              alt="Appside - Création de sites web et de boutiques en ligne."
            />
          </FadeInSection>
          <FadeInSection direction={"left"} offset={50} disabled={isMobileView}>
            <CardContainer id="appside-web-service">
              <Card
                title={t("services.web.title")}
                content={<CardText>{t("services.web.text")}</CardText>}
                width={isMobileView ? undefined : 400}
                footer={
                  <CardFooter>
                    <Link
                      text={t("services.button")}
                      to={`mailto:${appsideMail}`}
                      variant="secondary"
                    />
                  </CardFooter>
                }
              />
            </CardContainer>
          </FadeInSection>
        </ServiceRow>
        <ServiceRow>
          {isTabletView && (
            <FadeInSection direction={"top"} offset={50}>
              <img
                src="assets/services/service-mobile.png"
                alt="Appside - Développement d'applications web & mobiles."
              />
            </FadeInSection>
          )}
          <FadeInSection
            direction={isTabletView ? "left" : "right"}
            offset={50}
            disabled={isMobileView}
          >
            <CardContainer id="appside-mobile-service">
              <Card
                title={t("services.mobile.title")}
                content={<CardText>{t("services.mobile.text")}</CardText>}
                width={isMobileView ? undefined : 400}
                footer={
                  <CardFooter>
                    <Link
                      text={t("services.button")}
                      to={`mailto:${appsideMail}`}
                      variant="secondary"
                    />
                  </CardFooter>
                }
              />
            </CardContainer>
          </FadeInSection>
          {!isTabletView && (
            <FadeInSection direction="top" offset={50}>
              <img
                src="assets/services/service-mobile.png"
                alt="Appside - Développement d'applications web & mobiles."
              />
            </FadeInSection>
          )}
        </ServiceRow>
        <ServiceRow>
          <FadeInSection direction="top" offset={50}>
            <img
              src="assets/services/service-consulting.png"
              alt="Appside - Services de consultance informatique."
            />
          </FadeInSection>
          <FadeInSection direction={"left"} offset={50} disabled={isMobileView}>
            <CardContainer id="appside-consulting-service">
              <Card
                title={t("services.consulting.title")}
                content={<CardText>{t("services.consulting.text")}</CardText>}
                width={isMobileView ? undefined : 400}
                footer={
                  <CardFooter>
                    <Link
                      text={t("services.button")}
                      to={`mailto:${appsideMail}`}
                      variant="secondary"
                    />
                  </CardFooter>
                }
              />
            </CardContainer>
          </FadeInSection>
        </ServiceRow>
      </ServiceRows>
    </SectionContainer>
  );
};
