import styled from "styled-components";
import { devices } from "../../constants";

export const SocialWidgetWrapper = styled.div`
  position: fixed;
  top: 25vh;
  right: 0;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.bg.quaternary};
  padding: 20px 10px;
  border-radius: 25px 0px 0px 25px;
  z-index: 10;
`;

export const SocialItem = styled.a`
  color: ${(props) => props.theme.text.secondary};
  margin: 5px 0;
  &:hover {
    color: ${(props) => props.theme.text.tertiary};
  }
`;
