import { useQuery, UseQueryResult } from "react-query";
import { fetchApi } from ".";
import { Post } from "../models/blog.models";

export const usePosts = (search?: string): UseQueryResult<Post[]> => {
  const query = search && search !== "" ? `?search=${search}` : "";
  return useQuery(
    "posts",
    async () => await fetchApi(`${process.env.API_URL}/posts${query}`),
    { enabled: false }
  );
};

export const usePost = (slug?: string): UseQueryResult<Post[]> => {
  return useQuery(
    "posts",
    async () => await fetchApi(`${process.env.API_URL}/posts?slug=${slug}`),
    { enabled: !!(slug && slug !== "") }
  );
};
