import React, { FC, ReactElement } from "react";
import Slider from "react-slick";
import { CarouselWrapper } from "./style";
import { useIsMobileView, useIsTabletView } from "../../hooks";

export type CarouselProps = {
  items: JSX.Element[];
  slidesToShow: number;
  shouldDisplayDot: boolean;
};
export const Carousel: FC<CarouselProps> = (props): ReactElement => {
  const { items, slidesToShow, shouldDisplayDot } = props;
  const isMobileView = useIsMobileView();
  const isTabletView = useIsTabletView();

  const settings = {
    arrows: true,
    dots: isMobileView || isTabletView ? false : shouldDisplayDot,
    infinite: true,
    speed: 500,
    slidesToShow: isMobileView ? 1 : isTabletView ? 3 : slidesToShow,
    slidesToScroll: isMobileView || isTabletView ? 1 : slidesToShow,
    lazyLoadType: "progressive",
  };
  return (
    <CarouselWrapper>
      <Slider {...settings}>{items.map((item) => item)}</Slider>
    </CarouselWrapper>
  );
};
