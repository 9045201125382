import styled from "styled-components";

export const ErrorContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-height: 500px;
  }
`;
