import {
  DialogTitle,
  DialogContentText,
  DialogActions,
  Dialog,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledDialogContainer = styled(Dialog)`
  position: relative;
  & .MuiDialog-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    margin: 10px 10px;
  }

  & .MuiPaper-root {
    background-color: ${(props) => props.theme.bg.primary};
    margin: 25px 0;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.subTitle};
`;

export const StyledDialogContentText = styled(DialogContentText)`
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.text};
`;

export const StyledDialogActions = styled(DialogActions)`
  button,
  span {
    text-transform: unset;
    font-family: ${(props) => props.theme.font.primary};
    color: ${(props) => props.theme.text.primary};
    font-size: ${(props) => props.theme.fontSize.text};
  }
`;

export const CloseButton = styled(IconButton)`
  width: 48px;
  height: 48px;
`;

export const StyledCookieDialogActions = styled(DialogActions)`
  .MuiButton-textPrimary {
    background-color: ${(props) => props.theme.colors.primary};

    &:hover {
      background-color: ${(props) => props.theme.colors.secondary};
    }
  }

  .MuiButton-textSecondary {
    background-color: transparent;
    &:hover {
      background-color: transparent;
      span {
        color: ${(props) => props.theme.text.primary};
      }
    }
  }

  .MuiButton-textPrimary,
  span {
    text-transform: none;
    font-family: ${(props) => props.theme.font.primary};
    color: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.theme.fontSize.text};
  }

  .MuiButton-textSecondary,
  span {
    text-transform: none;
    font-family: ${(props) => props.theme.font.primary};
    color: ${(props) => props.theme.text.primary};
    font-size: ${(props) => props.theme.fontSize.text};
  }
`;
export const CookieLink = styled(Link)`
  color: ${(props) => props.theme.text.tertiary};
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${(props) => props.theme.text.primary};
  }
`;
