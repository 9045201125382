import styled from "styled-components";
import { devices } from "../../constants";

export const HeadlineContainer = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  text-align: center;
  width: 100%;
  @media only screen and ${devices.md} {
    text-align: left;
  }
`;

export const BigTitle = styled.h1`
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.mobileTitle};
  margin-bottom: 50px;
  margin-top: 0px;
  text-align: center;
  font-weight: bold;
  @media only screen and ${devices.xl} {
    max-width: 500px;
    font-size: ${(props) => props.theme.fontSize.title};
  }
  @media only screen and ${devices.md} {
    text-align: left;
  }
`;

export const SubTitle = styled.h1`
  color: ${(props) => props.theme.text.primary};
  font-size: ${(props) => props.theme.fontSize.text};
  @media only screen and ${devices.xl} {
    max-width: 500px;
    font-size: ${(props) => props.theme.fontSize.subTitle};
  }
  margin-bottom: 50px;
  font-weight: 100;
  text-align: justify;
`;
