import React from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "../../components/Carousel";
import {
  AzureLogo,
  BitbucketLogo,
  ElasticLogo,
  GithubLogo,
  GitlabLogo,
  Html5Logo,
  JavascriptLogo,
  JestLogo,
  LessLogo,
  MobxLogo,
  NetCoreLogo,
  NodeJsLogo,
  NpmLogo,
  ReactLogo,
  ReactNativeLogo,
  ReduxLogo,
  RxJsLogo,
  SassLogo,
  SitefinityLogo,
  SplineLogo,
  SqlServerLogo,
  SwaggerLogo,
  TypescriptLogo,
  VisualStudioCodeLogo,
  VisualStudioLogo,
  WebpackLogo,
  WordpressLogo,
  XamarinLogo,
  YarnLogo,
} from "../../components/Logo";
import { SectionTitle } from "../../components/SectionTitle";
import { TechnoItem } from "./TechnoItem";
import { SectionContainer } from "../../components/SectionContainer";
import { FadeInSection } from "../../components/MotionSection";
import { useWindowSize } from "@uidotdev/usehooks";
import { breakpoints } from "../../constants";

export const Techno = () => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const isMobileView = size && size.width && size.width <= breakpoints.sm;
  const technologies = [
    { title: "React", logo: <ReactLogo />, link: "https://fr.react.dev/" },
    { title: "Redux", logo: <ReduxLogo />, link: "https://redux.js.org/" },
    { title: "MobX", logo: <MobxLogo />, link: "https://mobx.js.org/" },
    { title: "Jest", logo: <JestLogo />, link: "https://jestjs.io/" },
    { title: "Node Js", logo: <NodeJsLogo />, link: "https://nodejs.org/en" },
    { title: "RxJs", logo: <RxJsLogo />, link: "https://rxjs.dev/" },
    {
      title: "Webpack",
      logo: <WebpackLogo />,
      link: "https://webpack.js.org/",
    },
    { title: "Npm", logo: <NpmLogo />, link: "https://www.npmjs.com/" },
    { title: "Yarn", logo: <YarnLogo />, link: "https://yarnpkg.com/" },
    {
      title: ".Net Core",
      logo: <NetCoreLogo />,
      link: "https://learn.microsoft.com/en-gb/dotnet/core/introduction",
    },
    {
      title: "Azure",
      logo: <AzureLogo />,
      link: "https://azure.microsoft.com/",
    },
    {
      title: "Elasticsearch",
      logo: <ElasticLogo />,
      link: "https://www.elastic.co/",
    },
    { title: "Swagger", logo: <SwaggerLogo />, link: "https://swagger.io/" },
    {
      title: "Gitlab",
      logo: <GitlabLogo />,
      link: "https://about.gitlab.com/",
    },
    { title: "Github", logo: <GithubLogo />, link: "https://github.com/" },
    {
      title: "Bitbucket",
      logo: <BitbucketLogo />,
      link: "https://bitbucket.org/",
    },
    {
      title: "Sql Server",
      logo: <SqlServerLogo />,
      link: "https://www.microsoft.com/fr-be/sql-server",
    },
    {
      title: "Wordpress",
      logo: <WordpressLogo />,
      link: "https://wordpress.com/",
    },
    {
      title: "Sitefinity",
      logo: <SitefinityLogo />,
      link: "https://www.progress.com/fr/cms-sitefinity",
    },
    { title: "Spline", logo: <SplineLogo />, link: "https://spline.design/" },
    {
      title: "Visual Studio",
      logo: <VisualStudioLogo />,
      link: "https://visualstudio.microsoft.com/",
    },
    {
      title: "Visual Studio Code",
      logo: <VisualStudioCodeLogo />,
      link: "https://code.visualstudio.com/",
    },
    { title: "Sass", logo: <SassLogo />, link: "https://sass-lang.com/" },
    { title: "Less", logo: <LessLogo />, link: "https://lesscss.org/" },
    {
      title: "Typescript",
      logo: <TypescriptLogo />,
      link: "https://www.typescriptlang.org/",
    },
    {
      title: "Javascript",
      logo: <JavascriptLogo />,
      link: "https://developer.mozilla.org/fr/docs/Web/JavaScript",
    },
    {
      title: "Html5",
      logo: <Html5Logo />,
      link: "https://developer.mozilla.org/fr/docs/Glossary/HTML5",
    },
    {
      title: "React Native",
      logo: <ReactNativeLogo />,
      link: "https://reactnative.dev/",
    },
    {
      title: "Xamarin",
      logo: <XamarinLogo />,
      link: "https://dotnet.microsoft.com/en-us/apps/xamarin",
    },
  ];
  return (
    <SectionContainer id="appside-tech">
      <FadeInSection direction={"right"} offset={50}>
        <SectionTitle
          title={t("technologies.title")}
          subtitle={t("technologies.subtitle")}
        />
      </FadeInSection>
      <div style={{ width: "100%" }}>
        <Carousel
          slidesToShow={4}
          shouldDisplayDot
          items={technologies.map((tech, index) => (
            <TechnoItem
              key={index}
              title={tech.title}
              logo={tech.logo}
              link={tech.link}
            />
          ))}
        />
      </div>
    </SectionContainer>
  );
};
