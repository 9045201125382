import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledTextField = styled(TextField)`
  font-size: ${(props) => props.theme.fontSize.text};
  .MuiInputLabel-root {
    color: ${(props) => props.theme.text.primary};
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize.text};
    font-family: ${(props) => props.theme.font.primary};
    &.Mui-focused {
      color: ${(props) => props.theme.text.primary};
      font-size: ${(props) => props.theme.fontSize.text};
      font-family: ${(props) => props.theme.font.primary};
    }
  }

  fieldset {
    border-radius: 15px;
    border: 2px solid ${(props) => props.theme.border.tertiary} !important;
    color: ${(props) => props.theme.text.primary};
    font-size: ${(props) => props.theme.fontSize.text};
    font-family: ${(props) => props.theme.font.primary};
  }

  .MuiInputBase-root {
    background: transparent !important;
    border: none;
    &:after {
      border-bottom: 2px solid ${(props) => props.theme.border.tertiary};
    }
    &:before: {
      border-bottom: 1px solid ${(props) => props.theme.border.primary} !important;
      transition: ${(props) => props.theme.border.primary} 200ms
        cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &:hover {
      background: transparent !important;
    }
    .MuiFilledInput-input {
      padding: 10px 5px;
      color: ${(props) => props.theme.text.primary};
      background-color: ${(props) => props.theme.bg.secondary};
    }
  }
`;
