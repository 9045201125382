import styled from "styled-components";
import { devices } from "../../constants";

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props.theme.bg.quaternary};
  }
  &.secondary {
    svg {
      color: ${(props) => props.theme.bg.secondary};
    }
  }
`;
